<template>
  <div style="position: absolute; left: 0px; top: 0px; z-index: 20">
    <img style="position: relative" :src="panel" alt="" />
    <div
      style="
        color: white;
        font-size: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      {{number}}
    </div>
  </div>
</template>

<script>
import panel from "../assets/icon/faq/panel.png";
export default {
  props: ["number"],
  data() {
    return {
      panel: panel,
    };
  },
};
</script>

<style>
</style>