<template>
  <v-container
    style="padding: 0px"
    class="mw-1150 d-flex justify-space-between"
  >
    <Loading :loading="isLoading"></Loading>
    <v-container>
      <div class="mt-2">
        <BreadCrumbs :items="items"></BreadCrumbs>
      </div>
      <div class="mb-7 mt-1">
        <Topic eng="FAQ" thai="คำถามที่พบบ่อย"></Topic>
      </div>
      <div
        class="mt-5"
        style="position: relative"
        v-for="item in faqList"
        :key="item.order"
      >
        <FAQNumber :number="item.order"></FAQNumber>
        <v-expansion-panels class="pt-10 pl-10">
          <v-expansion-panel style="">
            <v-expansion-panel-header style="padding-left: 50px; height: 80px">
              <span class="font-18 font-w-600">{{ item.topic }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-5">
              <div v-html="item.content"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Topic from "../components/Topic.vue";
import Loading from "../components/Loading.vue";
import BreadCrumbs from "../components/BreadCrumbs.vue";
import FAQNumber from "../components/FAQNumber.vue";
export default {
  components: {
    BreadCrumbs,
    Loading,
    Topic,
    FAQNumber,
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("FAQ/get").then((res) => {
      this.faqList = res;
    });
    this.isLoading = false;
  },
  data() {
    return {
      faqList: [],
      isLoading: false,
      items: [
        {
          text: "หน้าแรก",
          disabled: false,
          href: "/",
        },
        {
          text: "คำถามที่พบบ่อย",
          disabled: true,
        },
      ],
    };
  },
};
</script>

<style>
.v-expansion-panel-header {
  background-color: white !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.v-expansion-panel {
  background-color: transparent !important;
}
.v-expansion-panel::before {
  border: none !important;
  box-shadow: none !important;
}
</style>